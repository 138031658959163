
import { defineComponent, ref } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { useRoute } from "vue-router";
import { VueEditor } from "vue3-editor";
import { UploadFilled } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { event } from "vue-gtag";

interface Submission {
  note?: number;
  files?: string[];
  answer?: string;
}

export default defineComponent({
  name: "card-research-homework",
  components: { VueEditor },
  props: {
    cardClasses: String,
    deadline: String,
    marked: Boolean,
    hid: String,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();

    const currentStudent = store.getters.currentStudent;

    const answer = ref<string>("");
    const submitStat = ref<number>(0);
    const submitError = ref<boolean>(false);
    const submitMethod = ref<number>(1);

    const submission = ref<Submission>({});

    const submitButton = ref<HTMLButtonElement | null>(null);

    const uploadPercentage = ref(0);

    ApiService.get(
      `/parents/homework/${route.params.hid}/submission/${currentStudent._id}`
    )
      .then(({ data }) => {
        if (data) {
          submitStat.value = 2;
          submission.value = data;
          submission.value.files = data.files.map((file) => {
            return {
              name: getFileName(file),
              type: getFileType(file),
              url: getFileURL(file),
              shortName: getShortFileName(getFileName(file)),
            };
          });
        } else {
          submitStat.value = 1;
        }
      })
      .catch((e) => {
        console.log(e);
      });

    // eslint-disable-next-line
    const upload = ref<any>(null);

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const data = new FormData();
      if (upload.value)
        uploadFiles.value.forEach((file) => {
          data.append("files", file.raw, file.raw.name);
        });

      if (answer.value) data.append("answer", answer.value);

      if (data.get("files") || data.get("answer"))
        ApiService.put(
          `/parents/homework/${route.params.hid}/submit/${currentStudent._id}`,
          data as AxiosRequestConfig,
          {
            onUploadProgress: function (progressEvent) {
              uploadPercentage.value = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          } as any
        )
          .then(() => {
            event("Submit homework", {
              event_category: "Homework",
              event_label: "Homework section",
              value: 1,
            });

            submitStat.value = 2;
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
          })

          .catch((e) => {
            console.log(e);
          });
      else {
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
        submitButton.value?.removeAttribute("data-kt-indicator");
        submitError.value = true;
        setTimeout(() => {
          submitError.value = false;
        }, 5000);
      }
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    const customToolbar = [
      ["bold", "italic", "underline"],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
    ];

    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileType = (file: string): string => {
      const type = file.split(".")[1];
      if (
        [
          "pdf",
          "png",
          "jpg",
          "ppt",
          "xls",
          "txt",
          "gif",
          "doc",
          "pptx",
          "xlsx",
          "docx",
          "zip",
          "mkv",
          "avi",
          "mp4",
          "mp3",
        ].includes(type)
      )
        return type;
      return "file";
    };

    const getFileURL = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const name = file.split(".")[0];
      if (name.length > 19) return name.substring(0, 19);
      return name;
    };

    return {
      submitMethod,
      submitButton,
      upload,
      submit,
      answer,
      moment,
      t,
      submitStat,
      submitError,
      submission,
      updateUploadFiles,
      uploadPercentage,
      customColorMethod,
      customToolbar,
      UploadFilled,
    };
  },
});
